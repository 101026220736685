import PropTypes from "prop-types"
import React from "react"

import Header from "./Header.En"
import Footer from "./Footer.En"

const Layout = ({ title, children }) => (
  <div className="flex flex-col min-h-screen font-sans text-gray-900">
    <Header />

    <main className="flex-1 w-full max-w-screen-xl px-4 pt-16 pb-24 mx-auto sm:px-8">
      <h1 className="title text-center mb-24">{title}</h1>
      {children}
    </main>

    <Footer />
  </div>
)

Layout.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
