import { Link } from "gatsby"
import React from "react"

import Language from "../components/Language"
import { RoutesEn } from "../components/Routes"

import Teamviewer from "../icons/teamviewer.svg"

const Footer = () => (
  <footer>
    <div className="flex flex-wrap justify-between max-w-screen-xl p-4 mx-auto sm:p-8">
      <div className="w-full sm:w-2/4 md:w-1/4 mb-8 md:mb-0">
        <h4>Address</h4>
        <p className="font-display font-light leading-5 mb-4">
          Lähdekuja 6 A 10
          <br />
          13210 Hämeenlinna
          <br />
          Finland
        </p>
        <p className="font-display font-light leading-5">+358 50 3398198</p>
      </div>
      <div className="w-full sm:w-2/4 md:w-1/4 mb-8 md:mb-0">
        <h4>Navigate</h4>
        {RoutesEn.map(link => (
          <Link
            className="block font-display font-light"
            key={link.title}
            to={link.route}
          >
            {link.title}
          </Link>
        ))}
      </div>
      <div className="w-full sm:w-2/4 md:w-1/4 mb-8 md:mb-0">
        <h4>Language</h4>
        <Language title="Select" />
      </div>
      <div className="w-full sm:w-2/4 md:w-1/4 mb-8 md:mb-0">
        <h4>Remote Assistance</h4>
        <a href="https://get.teamviewer.com/8eyrq8m">
          <Teamviewer className="inline" />
          <span className="font-medium leading-5 tracking-wider ml-3">
            Download Teamviewer remote assistance
          </span>
        </a>
      </div>
      <div className="w-full font-display text-gray-500">
        © 2020 Kopernicus Oy
      </div>
    </div>
  </footer>
)

export default Footer
