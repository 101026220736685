import { Link } from "gatsby"
import React from "react"

import Button from "../components/Button"
import Menu from "../components/Menu"
import { RoutesEn } from "../components/Routes"

import Logo from "../images/logo.svg"

const Header = () => (
  <header>
    <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto sm:p-8">
      <Link to="/en">
        <h1 className="flex items-center no-underline">
          <Logo className="h-12 mr-2 z-50" />
        </h1>
      </Link>

      <div className="flex flex-1 justify-end md:hidden">
        <Menu items={RoutesEn} />
      </div>

      <div className="hidden md:flex">
        <nav className="flex items-center w-auto p-4 rounded-full bg-white shadow-orange">
          {RoutesEn.map(
            link =>
              !link.button && (
                <Link
                  className="font-body font-light leading-5 text-gray-600 hover:text-gray-700 mx-3"
                  activeStyle={{ color: "var(--bittersweet)" }}
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              )
          )}
        </nav>

        {RoutesEn.map(
          link =>
            link.button && (
              <Button pill className="ml-8" key={link.title} to={link.route}>
                {link.title}
              </Button>
            )
        )}
      </div>
    </div>
  </header>
)

export default Header
