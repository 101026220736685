import React from "react"

import Layout from "../layouts/En"

import SEO from "../components/SEO"

export const NotFoundPage = () => (
  <Layout title="404 - Page Not Found">
    <SEO title="Page Not Found" />
    <h3 className="text-center">
      Looks like you found a route that leads nowhere...
    </h3>
  </Layout>
)

export default NotFoundPage
